/** @format */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdf: null,
  filename: null,
};

const slice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    setPDF: (state, action) => {
      state.pdf = action.payload.pdf;
      state.filename = action.payload.filename;
    },
    clearPDF: (state, action) => {
      state.pdf = null;
      state.filename = null;
    },
  },
});

export const { setPDF, clearPDF } = slice.actions;

export const selectPDF = (state) => (state && state.pdf ? state.pdf.pdf : null);

export const selectPDFFilename = (state) =>
  state && state.pdf ? state.pdf.filename : null;

export default slice.reducer;
