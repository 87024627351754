/** @format */

import {
  faEnvelope,
  faMessageCheck,
  faMessageSms,
  faPhoneSlash,
  faPhoneVolume,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { api, getApiUrl } from '../../../api.ts';
import { getEventActionIcon } from '../../../utils/constants';
import { parseFieldError } from '../../../utils/errors';
import BoxLoader from '../../misc/BoxLoader';
import NewTooltip from '../../misc/NewTooltip';

export const CommunicationBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // right: -3,
    //    top: 10,
    //    right: 9,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
    //    borderTopRightRadius: 0,
    //    borderTopLeftRadius: 0,
    //    borderBottomRightRadius: 0,
    //    padding: '0 1px',
    //    fontSize: '75%',
    //    minWidth: '10px',
    //    lineHeight: 'normal',
    //    pointerEvents: 'none',
    //    textAlign: 'right',
  },
}));

function EventListTooltip({ currentEvents, previousEvents, loading }) {
  const [showPrevious, setShowPrevious] = useState(false);

  const handleShowPreviousClick = () => {
    setShowPrevious(!showPrevious);
  };

  return (
    <BoxLoader loading={loading}>
      <Box>
        <Typography variant="h5" px={2} pt={2}>
          Communications :
        </Typography>
        <List
          sx={{
            width: '100%',
            height: 200,
            maxHeight: 200,
            overflowY: 'auto',
            px: 2,
            pb: 2,
          }}
          dense
        >
          {currentEvents.map((event) => (
            <Fragment key={event.id}>
              <ListItem
                key={event.id}
                secondaryAction={
                  <FontAwesomeIcon
                    icon={getEventActionIcon(event.action)}
                    size="1x"
                  />
                }
                disableGutters
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`${
                      event.user.first_name
                    } ${event.user.last_name.toUpperCase()}.`}
                    src={`${getApiUrl()}/avatar/${event.user.id}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={event.action?.name}
                  secondary={moment(event.datetime).format('DD/MM/YYYY LT')}
                />
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
          {previousEvents && previousEvents.length > 0 && (
            <>
              <ListItem button onClick={handleShowPreviousClick}>
                <Typography variant="h6" color="primary" px={2} align="center">
                  Communications précédentes
                </Typography>
              </ListItem>
              {showPrevious &&
                previousEvents.map((event) => (
                  <Fragment key={event.id}>
                    <ListItem
                      key={event.id}
                      secondaryAction={
                        <FontAwesomeIcon
                          icon={getEventActionIcon(event.action)}
                          size="1x"
                        />
                      }
                      disableGutters
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={`${
                            event.user.first_name
                          } ${event.user.last_name.toUpperCase()}.`}
                          src={`${getApiUrl()}/avatar/${event.user.id}`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={event.action?.name}
                        secondary={moment(event.datetime).format(
                          'DD/MM/YYYY LT',
                        )}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </Fragment>
                ))}
            </>
          )}
          {!loading &&
            ((!previousEvents && !currentEvents) ||
              (previousEvents.length < 1 && currentEvents.length < 1)) && (
              <Alert severity="info">
                Aucune communication n&apos;a été enregistrée pour ce lead.
              </Alert>
            )}
        </List>
      </Box>
    </BoxLoader>
  );
}

EventListTooltip.propTypes = {
  currentEvents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  previousEvents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

function DashboardInlineEventManager({ lead, onChange, children, fullWidth }) {
  const [loading, setLoading] = useState(false);
  const [communicationEvents, setCommunicationEvents] = useState([]);
  const [communicationEventsLoading, setCommunicationEventsLoading] =
    useState(false);
  const [currentCommunicationEvents, setCurrentCommunicationEvents] = useState(
    [],
  );
  const [previousCommunicationEvents, setPreviousCommunicaionEvents] = useState(
    [],
  );

  useEffect(() => {
    const current = [];
    const previous = [];
    communicationEvents.forEach((event) => {
      if (
        event.lead_event_stage &&
        lead.current_event_stage &&
        event.lead_event_stage === lead.current_event_stage
      ) {
        current.push(event);
      } else {
        previous.push(event);
      }
    });
    setCurrentCommunicationEvents(current);
    setPreviousCommunicaionEvents(previous);
  }, [communicationEvents]);

  const { enqueueSnackbar } = useSnackbar();
  const loadCommunicationEvents = () => {
    setCommunicationEventsLoading(true);
    api
      .get(`/events/communication/?lead=${lead.id}`)
      .then((result) => {
        setCommunicationEvents(result.data.results);
        setCommunicationEventsLoading(false);
      })
      .catch((err) => {
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setCommunicationEventsLoading(false);
      });
  };

  const addCommunicationEvent = (action) => {
    setLoading(true);
    const data = {
      lead: lead.id,
      action,
    };
    api
      .post('/events/communication/', {}, data)
      .then((result) => {
        enqueueSnackbar('Cette information a bien été prise en compte.', {
          variant: 'success',
        });
        setLoading(false);
        loadCommunicationEvents();
        if (onChange) {
          onChange(lead);
        }
      })
      .catch((err) => {
        const { message } = parseFieldError(err);
        if (message) enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <NewTooltip
      placement="bottom"
      content={
        <EventListTooltip
          currentEvents={currentCommunicationEvents}
          previousEvents={previousCommunicationEvents}
          loading={communicationEventsLoading}
        />
      }
      onOpen={loadCommunicationEvents}
      isCommunicationTooltip
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <div>
          <BoxLoader loading={loading}>
            <ButtonGroup
              variant="contained"
              color="secondary"
              fullWidth={fullWidth}
              disabled={loading}
              size="medium"
            >
              <NewTooltip content="Appel manqué" key="Appel manqué">
                <CommunicationBadge
                  badgeContent={lead.has_missed_call}
                  color="error"
                  overlap="circular"
                >
                  <Button onClick={() => addCommunicationEvent('missed_call')}>
                    <FontAwesomeIcon icon={faPhoneSlash} />
                  </Button>
                </CommunicationBadge>
              </NewTooltip>
              <NewTooltip content="Répondeur" key="Répondeur">
                <CommunicationBadge
                  badgeContent={lead.has_voicemail}
                  color="error"
                  overlap="circular"
                >
                  <Button onClick={() => addCommunicationEvent('voicemail')}>
                    <FontAwesomeIcon icon={faPhoneVolume} />
                  </Button>
                </CommunicationBadge>
              </NewTooltip>
              <NewTooltip content="Envoi SMS" key="Envoi SMS">
                <CommunicationBadge
                  badgeContent={lead.has_text_message_sent}
                  color="error"
                  overlap="circular"
                >
                  <Button
                    onClick={() => addCommunicationEvent('text_message_sent')}
                  >
                    <FontAwesomeIcon icon={faMessageSms} />
                  </Button>
                </CommunicationBadge>
              </NewTooltip>
              <NewTooltip content="Envoi mail" key="Envoi mail">
                <CommunicationBadge
                  badgeContent={lead.has_mail_sent}
                  color="error"
                  overlap="circular"
                >
                  <Button onClick={() => addCommunicationEvent('mail_sent')}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Button>
                </CommunicationBadge>
              </NewTooltip>
              <NewTooltip
                content="Communication établie"
                key="Communication établie"
              >
                <Button
                  onClick={() => addCommunicationEvent('communication')}
                  sx={{
                    ...(lead.has_tried_communication &&
                    lead.has_tried_communication > 0
                      ? {
                          bgcolor: 'warning.main',
                          '&:hover': {
                            bgcolor: 'warning.dark',
                          },
                        }
                      : {}),
                    ...(lead.has_communication && lead.has_communication > 0
                      ? {
                          bgcolor: 'primary.main',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                        }
                      : {}),
                  }}
                >
                  <FontAwesomeIcon icon={faMessageCheck} />
                </Button>
              </NewTooltip>
            </ButtonGroup>
          </BoxLoader>
          {/* <Paper
              elevation={2}
              sx={{
                backgroundColor: 'primary.main',
                height: 26,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.contrastText',
                  fontSize: (theme) => theme.typography.pxToRem(14),
                  lineHeight: (theme) => theme.typography.pxToRem(26),
                  textAlign: 'center',
                }}
              >
                Communication établie
                <Box component="span" ml={1}>
                  <FontAwesomeIcon icon={faCheckDouble} />
                </Box>
              </Typography>
            </Paper> */}
        </div>
      )}
    </NewTooltip>
  );
}

DashboardInlineEventManager.propTypes = {
  lead: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};

DashboardInlineEventManager.defaultProps = {
  children: undefined,
  fullWidth: true,
};

export default DashboardInlineEventManager;
