/** @format */

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { api, getApiUrl } from '../api.ts';
import { selectAuth } from '../redux/authSlice';

export const MESSAGE_QUEUE_UPDATED = 'message-queue-updated';

const UserWebSocketContext = createContext();

export const useUserWebSocket = () => useContext(UserWebSocketContext);

function UserWebSocketProvider({ children }) {
  const [isConnected, setIsConnected] = useState(false);

  const { user, isAuthenticated } = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();

  const ws = useRef(null);

  const callback = (content) => {
    const { type, data } = content;
    console.log('WS Content:', content);
    console.log('WS Type:', type);
    console.log('WS Data:', data);
    if (type === 'notification') {
      enqueueSnackbar(data.content, {
        variant: 'webSocketNotification',
        persist: data.persist || false,
        autoHideDuration: data.duration || 5000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        title: data.title,
        customColor: data.color,
        customIcon: data.icon,
        customUserAvatarId: data.userAvatarId,
        customIsImportant: data.important,
      });
    }

    if (type === 'automaticmessagequeueentry') {
      const event = new CustomEvent(MESSAGE_QUEUE_UPDATED);
      window.dispatchEvent(event);
    }
  };

  const subscribe = (userId, cb) => {
    // callback.current = cb;
    // channels.current[channel] = callback;
  };

  const unsubscribe = (channel) => {
    // delete channels.current[channel];
  };

  const connect = () => {
    if (ws.current) return;
    try {
      const url = getApiUrl().replace(/^http(s?):\/\//, 'ws$1://');
      ws.current = new WebSocket(`${url}/ws/me/`);
    } catch (e) {
      console.error('Unable to connect to WS...', e);
    }
    if (ws.current) {
      ws.current.onopen = () => {
        setIsConnected(true);
        console.log('WS Connected');
      };
      ws.current.onclose = () => {
        setIsConnected(false);
        ws.current = null;
        console.log('WS Disconnected');
        if (user) {
          setTimeout(() => {
            connect();
          }, 5000);
        }
      };
      ws.current.onmessage = (message) => {
        const data = JSON.parse(message.data);
        callback(data);
      };
    }
  };

  useEffect(
    () => () => {
      if (ws.current) ws.current.close();
    },
    [],
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      connect();
    } else if (ws.current) {
      ws.current.close();
    }
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({ subscribe, unsubscribe, isWSConnected: isConnected }),
    [subscribe, unsubscribe, isConnected],
  );

  return (
    <UserWebSocketContext.Provider value={value}>
      {children}
    </UserWebSocketContext.Provider>
  );
}

UserWebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserWebSocketProvider, UserWebSocketContext };
