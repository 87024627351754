/** @format */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../api.ts';
import { compareBools, compareNumbers } from '../utils/sort';

const initialState = {
  lead: null,
  isLeadLoading: false,
  sites: [],
  site: null,
  isSiteLoading: false,
  deals: [],
  deal: null,
  isDealLoading: false,
};

const filterLeadSites = (lead) =>
  lead
    ? lead.sites
        .map((s) => ({ ...s, former_site: false }))
        .concat(lead.former_sites.map((s) => ({ ...s, former_site: true })))
        .sort(
          (a, b) =>
            compareBools(a.former_site, b.former_site, true) ||
            compareNumbers(a.id, b.id),
        )
    : [];

const filterSiteDeals = (site) =>
  site ? site.deals.sort((a, b) => compareNumbers(a.id, b.id)) : [];

const trackLeadLoadingState = (builder, asyncThunk) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.isLeadLoading = true;
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    state.lead = action.payload;
    state.sites = filterLeadSites(state.lead);
    state.isLeadLoading = false;
  });
  builder.addCase(asyncThunk.rejected, (state) => {
    state.isLeadLoading = false;
  });
};

const trackSiteLoadingState = (builder, asyncThunk) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.isSiteLoading = true;
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    state.site = action.payload;
    state.deals = filterSiteDeals(state.site);
    state.isSiteLoading = false;
  });
  builder.addCase(asyncThunk.rejected, (state) => {
    state.isSiteLoading = false;
  });
};

const trackDealLoadingState = (builder, asyncThunk) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.isDealLoading = true;
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    state.deal = action.payload;
    state.isDealLoading = false;
  });
  builder.addCase(asyncThunk.rejected, (state) => {
    state.isDealLoading = false;
  });
};

export const fetchLead = createAsyncThunk(
  'lead/fetchById',
  async (id, thunkAPI) => {
    const { lead: state } = thunkAPI.getState();
    const leadId = id || state?.lead?.id;
    if (!leadId) return null;
    const previousSiteCount = id ? 0 : state.sites.length;
    const response = await api.get(`leads/${leadId}/`);
    const lead = response.data;

    const sites = filterLeadSites(lead);
    let siteId = null;

    const storedSiteId = localStorage.getItem(`current-site-${lead.id}`);
    if (storedSiteId && storedSiteId !== 'null' && storedSiteId !== 0) {
      siteId = storedSiteId;
    } else if (!state.site) {
      siteId = sites?.[0]?.id;
    }
    if (previousSiteCount !== sites.length && previousSiteCount > 0) {
      siteId = sites?.[sites.length - 1]?.id;
    }
    if (siteId) {
      if (storedSiteId !== siteId) {
        localStorage.setItem(`current-site-${lead.id}`, siteId);
      }
      thunkAPI.dispatch(fetchSite({ id: siteId, leadId: lead.id }));
    }

    return lead;
  },
);

export const fetchSite = createAsyncThunk(
  'site/fetchById',
  async (obj, thunkAPI) => {
    const { lead: state } = thunkAPI.getState();
    const { id, leadId } = obj || { id: null, leadId: null };
    const siteId = id || state?.site?.id;
    if (!siteId) return null;
    const previousDealCount = id ? 0 : state.deals.length;
    const response = await api.get(`leads-sites/${siteId}/`);
    const site = response.data;

    const deals = filterSiteDeals(site);
    let dealId = null;

    const storedDealId = localStorage.getItem(`current-deal-${site.id}`);
    if (storedDealId && storedDealId !== 'null' && storedDealId !== 0) {
      dealId = storedDealId;
    } else if (!state.deal) {
      dealId = deals?.[0]?.id;
    }
    if (previousDealCount !== deals.length && previousDealCount > 0) {
      dealId = deals?.[deals.length - 1]?.id;
    }
    if (dealId) {
      if (storedDealId !== dealId) {
        localStorage.setItem(`current-deal-${site.id}`, dealId);
      }
      thunkAPI.dispatch(fetchDeal({ id: dealId, siteId: site.id }));
    }

    try {
      localStorage.setItem(`current-site-${leadId || state.lead.id}`, siteId);
    } catch (_) {}

    return site;
  },
);

export const fetchDeal = createAsyncThunk(
  'deal/fetchById',
  async (obj, thunkAPI) => {
    const { lead: state } = thunkAPI.getState();
    const id = obj?.id || null;
    const siteId = obj?.siteId || null;
    const showArchived = obj?.showArchived || false;
    const dealId = id || state?.deal?.id;
    if (!dealId) {
      return null;
    }
    const response = await api.get(
      `deals/${dealId}/?is_archived=${showArchived}`,
    );
    const deal = response.data;

    try {
      localStorage.setItem(`current-deal-${siteId || state.site.id}`, dealId);
    } catch (_) {}

    return deal;
  },
);

const slice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    clearLead: (state) => {
      state.lead = null;
      state.isLeadLoading = false;
      state.sites = [];
      state.site = null;
      state.isSiteLoading = false;
      state.deals = [];
      state.deal = null;
      state.isDealLoading = false;
    },
    updateCurrentLead: (state, action) => {
      state.lead = action.payload;
    },
    updateCurrentSite: (state, action) => {
      state.site = action.payload;
    },
    updateCurrentDeal: (state, action) => {
      state.deal = action.payload;
    },
  },
  extraReducers: (builder) => {
    trackLeadLoadingState(builder, fetchLead);
    trackSiteLoadingState(builder, fetchSite);
    trackDealLoadingState(builder, fetchDeal);
  },
});

export const {
  clearLead,
  updateCurrentLead,
  updateCurrentSite,
  updateCurrentDeal,
} = slice.actions;

export const selectLead = (state) => state.lead;

export default slice.reducer;
