/** @format */

import { Tooltip, tooltipClasses, Zoom } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { cloneElement, useMemo } from 'react';

const ThemedTooltip = styled(
  ({ className, light, width, csx, color, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme, light, width, csx, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...(color
      ? {
          backgroundColor: theme.palette[color].main,
          color: theme.palette[color].contrastText,
        }
      : {
          backgroundColor: light ? theme.palette.background.default : '#222222',
          color: light ? theme.palette.text.primary : undefined,
        }),
    fontSize: theme.typography.pxToRem(14),
    boxShadow: light ? theme.customShadows.z24 : undefined,
    width: width || undefined,
    ...(csx || {}),
  },
}));

// const ColoredTooltip = styled(({ color, ...props }) => (
//  <ThemedTooltip {...props} />
// ))(({ theme, color }) =>
//  color
//    ? {
//        [`& .${tooltipClasses.tooltip}`]: {
//          backgroundColor: theme.palette[color].main,
//          color: theme.palette[color].contrastText,
//        },
//      }
//    : {},
// );

const CommunicationTooltip = styled(
  ({ className, light, width, csx, PopperProps, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={{ ...PopperProps, sx: { zIndex: 1499 } }}
    />
  ),
)(({ theme, csx }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    fontSize: theme.typography.pxToRem(14),
    width: 400,
    boxShadow: theme.customShadows.z24,
    color: theme.palette.text.primary,
    ...(csx || {}),
  },
}));

const CommentTooltip = styled(
  ({ className, light, width, csx, PopperProps, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={{ ...PopperProps, sx: { zIndex: 1499 } }}
    />
  ),
)(({ theme, csx }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    fontSize: theme.typography.pxToRem(14),
    width: 600,
    maxWidth: 'none',
    boxShadow: theme.customShadows.z24,
    color: theme.palette.text.primary,
    ...(csx || {}),
  },
}));

const ProvenanceTooltip = styled(
  ({ className, light, width, csx, PopperProps, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={{ ...PopperProps, sx: { zIndex: 1499 } }}
    />
  ),
)(({ theme, csx }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    fontSize: theme.typography.pxToRem(14),
    width: 400,
    maxWidth: 400,
    boxShadow: theme.customShadows.z24,
    color: theme.palette.text.primary,
    ...(csx || {}),
  },
}));

function NewTooltip({
  children,
  content,
  placement,
  onOpen,
  onClose,
  light,
  isCommunicationTooltip,
  isCommentTooltip,
  isProvenanceTooltip,
  width,
  csx,
  open,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  color,
  ...otherProps
}) {
  const Component = useMemo(() => {
    if (isCommunicationTooltip) {
      return CommunicationTooltip;
    }
    if (isCommentTooltip) {
      return CommentTooltip;
    }
    if (isProvenanceTooltip) {
      return ProvenanceTooltip;
    }
    //    if (color) {
    //    return ColoredTooltip;
    //    }
    return ThemedTooltip;
  }, [isCommunicationTooltip, isCommentTooltip]);
  return (
    <Component
      title={content}
      placement={placement}
      TransitionComponent={Zoom}
      onOpen={onOpen}
      onClose={onClose}
      light={light}
      width={width}
      csx={csx}
      open={open}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      color={color}
    >
      {cloneElement(children, otherProps)}
    </Component>
  );
}

NewTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'left-start',
    'left',
    'left-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  light: PropTypes.bool,
  isCommunicationTooltip: PropTypes.bool,
  isCommentTooltip: PropTypes.bool,
  isProvenanceTooltip: PropTypes.bool,
  width: PropTypes.number,
  csx: PropTypes.shape(),
  open: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  color: PropTypes.string,
};

NewTooltip.defaultProps = {
  content: '',
  placement: 'top',
  onOpen: undefined,
  onClose: undefined,
  light: false,
  isCommunicationTooltip: false,
  isCommentTooltip: false,
  isProvenanceTooltip: false,
  width: undefined,
  csx: undefined,
  open: undefined,
  disableFocusListener: undefined,
  disableHoverListener: undefined,
  disableTouchListener: undefined,
  color: undefined,
};

export default NewTooltip;
