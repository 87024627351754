/** @format */

const errors = {
  user_already_exists: 'Un compte existe déjà avec cette adresse e-mail.',
  email_error: "Impossible d'envoyer l'e-mail de confirmation.",
  account_not_confirmed:
    'Veuillez confirmer votre compte via le lien reçu par e-mail.',
  account_locked: 'Ce compte est suspendu.',
  bad_credentials: 'Vos identifiants sont incorrects.',
  e_obj_not_loadable: 'Element Sellsy inconnu.',
  sellsy_error: 'Erreur Sellsy non répertoriée.',
  pvgis_horizon_error:
    "Problème de connection à PVGIS, impossible d'utiliser le masque d'ombrage.",
  update_production_error:
    'Problème de connection à PVGIS, impossible de mettre à jour des données de production.',
  file_size_is_zero: 'Oups ! Il semblerait que ce fichier soit vide !',
  no_mandate_file:
    "Impossible d'envoyer un mandat de représentation générique pour cette régie",
  cannot_send_email: "Impossible d'envoyer cet e-mail.",
  Invalid_To_header: 'Destinataire inconnu',
};

const statusErrors = {
  400: 'La requête est incomplète. (e400)',
  401: 'Requête non autorisée. (e401)',
  403: "Vous n'avez pas la permission. (e403)",
  404: "L'élément n'existe pas. (e404)",
  409: 'Un conflit est survenu. (e409)',
  429: 'Veuillez patienter entre chaque demande.',
  500: 'Erreur interne du serveur. (e500)',
  502: 'Le serveur ne répond pas. (e502)',
  503: 'Le serveur ne répond pas. (e503)',
};

const priorRequestErrors = {
  APICARTO:
    'Erreur de connexion au serveur IGN, vérifiez le point GPS et réessayez. (err: APICARTO)',
  CADPLOT:
    'Impossible de trouver la parcelle correspondante, vérifiez le point GPS et réessayez. (err: CADPLOT)',
  CADCITY:
    'Le nom de la commune ne correspond pas aux données du Cadastre, veuillez contacter un administrateur. (err: CADCITY)',
  CADPAGE:
    'Une erreur est survenue lors de la récupération des données de la parcelle, vérifiez le point GPS et réessayez. (err: CADPAGE)',
  CADFORM:
    'Une erreur est survenue lors de la récupération des données de la parcelle, vérifiez le point GPS et réessayez. (err: CADFORM)',
  CADPDF:
    'Une erreur est survenue lors de la récupération du plan de situation, veuillez contacter un administrateur. (err: CADPDF)',
  GEOITO:
    'Le serveur de cartographie Géoportail IGN ne répond pas, veuillez réessayer. (err: GEOITO)',
  GEOIER:
    'Une erreur est survenue lors de la récupération des cartes Géoportail, vérifiez le point GPS et réessayez. (err: GEOIER)',
};

/**
 * Parses field error
 * @param error {Object}
 * @param generic {Boolean}
 */
const parseField = (error, generic) =>
  Object.fromEntries(
    Object.entries(error)
      .map((er) => [
        // eslint-disable-next-line no-nested-ternary
        generic
          ? 'error'
          : !/^\d+$/.test(er[0])
          ? `${er[0].replace(/_([a-z0-9])/g, (g) => g[1].toUpperCase())}Error`
          : er[0],
        er[1],
      ])
      .map((er) => {
        const newEr = er;
        if (er[1].constructor === Array) {
          if (er[1][0].constructor !== String) {
            newEr[1] = parseField(er[1]);
          } else {
            newEr[1] = er[1].join(' ');
          }
        } else if (
          er[1].constructor === Object &&
          Object.keys(er[1]).length > 0
        ) {
          newEr[1] = parseField(er[1]);
        }
        return newEr;
      }),
  );

const isJsonBlob = (data) =>
  data instanceof Blob && data.type === 'application/json';

/**
 * Parses field error
 * @param error {Object} error
 * @param generic {Boolean} generic
 */
export const parseFieldError = (error, generic = false) => {
  let message = null;
  let state = null;
  const statusCode = error.response ? error.response.status : null;
  if (error.response && error.response.data && statusCode !== 429) {
    if (error.response.data instanceof ArrayBuffer) {
      try {
        const dec = new TextDecoder('utf-8');
        const data = dec.decode(error.response.data);
        const obj = JSON.parse(data);
        if (obj.message && obj.slug) {
          message = priorRequestErrors[obj.slug] || obj.message;
          return { message, state };
        }
      } catch (e) {
        return "Une erreur non répertoriée est survenue. Veuillez contacter l'équipe technique.";
      }
    }
    if (error.response.data.prior_request) {
      message = error.response.data.prior_request;
    }
    if (!message && error.response.data.error) {
      if (!errors[error.response.data.error]) {
        message = error.response.data.error;
      } else {
        message = errors[error.response.data.error];
      }
    }
    if (!message && Object.entries(error.response.data).length > 0) {
      state = parseField(error.response.data, generic);
    }
    if (!message && !state) {
      message = statusErrors[error.response.status];
    }
  } else if (error.response && error.response.status !== undefined) {
    message = statusErrors[error.response.status];
  }
  if (!message && !state) {
    message = 'Une erreur non répertoriée est survenue.';
  }
  return {
    state,
    message,
    statusCode,
  };
};

export const parseBlobError = async (error) => {
  const responseData = isJsonBlob(error?.response?.data)
    ? await error?.response?.data?.text()
    : error?.response?.data || {};
  const newError = error;
  newError.response = {
    ...newError.response,
    data: JSON.parse(responseData),
  };
  return parseFieldError(newError);
};

export default null;
