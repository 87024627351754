/** @format */

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import {
  BasicFuncs,
  Builder,
  MuiConfig,
  Query,
  Utils as QbUtils,
} from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';
import { clone } from 'lodash';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { api } from '../../../api.ts';
import { ThemeProvider } from '../../../theme';
import BoxLoader from '../../misc/BoxLoader';
import CustomAutocomplete from '../../misc/CustomAutocomplete';

const MATHS = {
  label: 'Opération',
  returnType: 'number',
  renderBrackets: ['(', ')'],
  renderSeps: ['', '', ''],
  allowSelfNesting: true,
  jsonLogic: ({ x, op, y }) => ({
    maths: [x, op, y],
  }),
  jsonLogicImport: (v) => {
    const x = v.maths[0];
    const op = v.maths[1];
    const y = v.maths[2];
    return [x, op, y];
  },
  jsonLogicCustomOps: {
    maths: {},
  },
  args: {
    x: {
      type: 'number',
      valueSources: ['field', 'value', 'func'],
    },
    op: {
      label: 'Op',
      type: 'select',
      defaultValue: 'add',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false,
        },
      },
      fieldSettings: {
        listValues: {
          add: '+',
          substract: '-',
          multiply: '*',
          divide: '/',
        },
      },
    },
    y: {
      type: 'number',
      valueSources: ['field', 'value', 'func'],
    },
  },
};

const baseSortConfig = {
  ...MuiConfig,
  conjunctions: {
    AND: MuiConfig.conjunctions.AND,
  },
  fields: {},
  funcs: {},
  operators: {
    equal: MuiConfig.operators.equal,
  },
  types: {
    ...MuiConfig.types,
    boolean: {
      ...MuiConfig.types.boolean,
      mainWidgetProps: {
        labelYes: 'Croissant',
        labelNo: 'Décroissant',
      },
      valueSources: ['value'],
      defaultValue: true,
    },
  },
  settings: {
    ...MuiConfig.settings,
    showNot: false,
    maxNesting: 1,
  },
};

const baseConfig = {
  ...MuiConfig,
  fields: {},
  funcs: {
    LOWER: BasicFuncs.LOWER,
    UPPER: BasicFuncs.UPPER,
    NOW: BasicFuncs.NOW,
    RELATIVE_DATETIME: BasicFuncs.RELATIVE_DATETIME,
    MATHS,
  },
  operators: {
    ...Object.entries(MuiConfig.operators)
      .filter(([key]) => key !== 'proximity')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {}),
  },
  settings: {
    ...MuiConfig.settings,
    caseValueField: {
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'tag1', title: 'Tag #1' },
          { value: 'tag2', title: 'Tag #2' },
        ],
      },
      mainWidgetProps: {
        valueLabel: 'Then',
        valuePlaceholder: 'Then',
      },
    },
    canRegroupCases: true,
    maxNumberOfCases: 10,
  },
};

const defaultQueryValue = QbUtils.loadTree({
  id: QbUtils.uuid(),
  type: 'group',
});

function AdminTypologyProductDependencyCompatibilityManager() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [typologyProductDependency, setTypologyProductDependency] =
    useState('');
  const [config, setConfig] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const [sortPreferenceTree, setSortPreferenceTree] =
    useState(defaultQueryValue);
  const [preferenceTree, setPreferenceTree] = useState(defaultQueryValue);

  const onSortPreferenceChange = useCallback((immutableTree, cnf) => {
    setSortPreferenceTree(immutableTree);
  }, []);

  const onPreferenceChange = useCallback((immutableTree, cnf) => {
    setPreferenceTree(immutableTree);
  }, []);

  const onTypologyProductDependencyChange = useCallback(
    async (event, value) => {
      setTypologyProductDependency(value);
      if (value) {
        setLoading(true);
        try {
          const localTypologyProductDependency = (
            await api.get(
              `/products/v2/typology-product-dependencies/${value.id}/`,
            )
          )?.data;
          const localSortConfig = clone(baseSortConfig);
          const localConfig = clone(baseConfig);
          const logicConfig = (
            await api.get(
              `/products/v2/logic-configuration/?typology=${localTypologyProductDependency.target_typology}&use_quote=True`,
            )
          )?.data;
          localSortConfig.fields = logicConfig.sort_fields;
          localConfig.fields = logicConfig.fields;
          setSortConfig(localSortConfig);
          setConfig(localConfig);
          if (
            localTypologyProductDependency.preference_sort_logic &&
            Object.keys(localTypologyProductDependency.preference_sort_logic)
              .length > 0
          ) {
            setSortPreferenceTree(
              QbUtils.loadFromJsonLogic(
                localTypologyProductDependency.preference_sort_logic,
                localSortConfig,
              ),
            );
          } else {
            setSortPreferenceTree(defaultQueryValue);
          }
          if (
            localTypologyProductDependency.preference_logic &&
            Object.keys(localTypologyProductDependency.preference_logic)
              .length > 0
          ) {
            setPreferenceTree(
              QbUtils.loadFromJsonLogic(
                localTypologyProductDependency.preference_logic,
                localConfig,
              ),
            );
          } else {
            setPreferenceTree(defaultQueryValue);
          }
        } catch (e) {
          enqueueSnackbar('Erreur lors du chargement des champs', {
            variant: 'error',
          });
        }
        setLoading(false);
      }
    },
    [],
  );

  const saveConfig = useCallback(async () => {
    setLoading(true);
    const sortPreferenceLogic = QbUtils.jsonLogicFormat(
      sortPreferenceTree,
      sortConfig,
    );
    const preferenceLogic = QbUtils.jsonLogicFormat(preferenceTree, config);
    const data = {
      preference_sort_logic: sortPreferenceLogic.logic || {},
      preference_logic: preferenceLogic.logic || {},
    };
    try {
      const response = await api.patch(
        `/products/v2/typology-product-dependencies/${typologyProductDependency.id}/`,
        {},
        data,
      );
      enqueueSnackbar('Configuration sauvegardée !', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar("Erreur lors de l'enregistrement", {
        variant: 'error',
      });
    }
    setLoading(false);
  }, [
    sortPreferenceTree,
    preferenceTree,
    config,
    typologyProductDependency?.id,
  ]);

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container">
        <div
          className="query-builder qb-lite"
          style={{
            margin: 0,
            marginRight: '-10px',
          }}
        >
          <Builder {...props} />
        </div>
      </div>
    ),
    [],
  );

  return (
    <ThemeProvider>
      <Container maxWidth="xl">
        <Typography variant="h3" gutterBottom>
          Configurateur de logique de dépendance produit
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={2}>
            <CustomAutocomplete
              name="typology"
              label="Configuration de dépendance"
              value={typologyProductDependency}
              endpoint={(input) =>
                `/products/v2/typology-product-dependencies/?search=${
                  input === 0 || input === undefined ? '' : input
                }`
              }
              getOptionLabel={(o) =>
                o ? `${o?.source_typology} → ${o?.target_typology}` : ''
              }
              onChange={onTypologyProductDependencyChange}
              size="small"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !typologyProductDependency}
              onClick={saveConfig}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
        <BoxLoader loading={loading} />
        {typologyProductDependency && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h4">Préférence</Typography>
            <Typography variant="subtitle2" color="gray" gutterBottom>
              Comment un produit est sélectionné en fonction de la configuration
              du devis
            </Typography>
            {sortConfig && sortPreferenceTree && !loading && (
              <Box
                sx={{
                  '& .rule--field': {
                    paddingRight: '20px',
                  },
                  '& .rule--operator': {
                    display: 'none !important;',
                  },
                }}
              >
                <Typography variant="h6">Tri</Typography>
                <Query
                  {...sortConfig}
                  value={sortPreferenceTree}
                  onChange={onSortPreferenceChange}
                  renderBuilder={renderBuilder}
                  key={`${typologyProductDependency.id}-sort-preference`}
                />
                {/* <div className="query-builder-result">
                    <div>
                      <pre>
                        {JSON.stringify(
                          QbUtils.jsonLogicFormat(
                            sortPreferenceTree,
                            sortConfig,
                          ),
                        )}
                      </pre>
                    </div>
                  </div> */}
              </Box>
            )}
            {config && preferenceTree && !loading && (
              <>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  Conditions
                </Typography>
                <Query
                  {...config}
                  value={preferenceTree}
                  onChange={onPreferenceChange}
                  renderBuilder={renderBuilder}
                  key={`${typologyProductDependency.id}-preference`}
                />
                <div className="query-builder-result">
                  <div>
                    {JSON.stringify(
                      QbUtils.jsonLogicFormat(preferenceTree, config),
                    )}
                  </div>
                </div>
              </>
            )}
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default AdminTypologyProductDependencyCompatibilityManager;
