/** @format */

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPDF,
  selectPDF,
  selectPDFFilename,
} from '../../../redux/pdfSlice';

const useStyles = makeStyles({
  dialog: {
    // position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    position: 'relative',
    flexGrow: 1,
  },
  iframe: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
});

function PDFViewer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pdf = useSelector(selectPDF);
  const filename = useSelector(selectPDFFilename);

  /**
   * Closes the dialog
   */
  const handleClose = () => {
    dispatch(clearPDF());
  };

  const download = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.target = '_blank';
    link.download =
      filename && filename.endsWith('.pdf') ? filename : `${filename}.pdf`;
    link.click();
  };

  return (
    <Dialog
      open={Boolean(pdf)}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth="xl"
      scroll="paper"
      fullScreen
      disableEnforceFocus
    >
      <DialogContent className={classes.content} sx={{ pb: 0 }}>
        <iframe
          src={pdf}
          title="PDF"
          frameBorder="0"
          className={classes.iframe}
        />
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              color="default"
            >
              Fermer
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              onClick={download}
              color="primary"
            >
              Télécharger
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default PDFViewer;
