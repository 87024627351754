/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, FormControlLabel, Tab, Tabs } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { api, getApiUrl } from '../../../api.ts';
import { ThemeProvider } from '../../../theme';
import palette from '../../../theme/palette';
import BoxLoader from '../../misc/BoxLoader';
import CustomAutocomplete from '../../misc/CustomAutocomplete';
import WebSocketNotification, {
  getColor,
  webSocketNotificationIcons,
} from '../../misc/NotistackVariants';

function AdminMessageManager() {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedUserAvatar, setSelectedUserAvatar] = useState('');
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isImportant, setIsImportant] = useState(false);
  const [isPersistent, setIsPersistent] = useState(false);
  const [duration, setDuration] = useState(5000);

  const [tabValue, setTabValue] = useState(0);
  const [formLoading, setFormLoading] = useState(false);

  const handleTabChange = (e, v) => {
    setTabValue(v);
    if (v === 0) {
      setSelectedUserAvatar('');
      setUrl('');
    } else if (v === 1) {
      setSelectedIcon('');
      setSelectedColor('');
      setUrl('');
    } else if (v === 2) {
      setSelectedUserAvatar('');
      setSelectedIcon('');
    }
  };

  const sendMessage = async () => {
    setFormLoading(true);
    try {
      await api.post(
        '/int/admin/message/',
        {},
        {
          user_ids: selectedUsers.map((u) => u.id),
          all_users: isAllUsers,
          title: title || null,
          content,
          important: isImportant,
          ...(tabValue === 2
            ? { icon: url || null }
            : { icon: selectedIcon || null }),
          color: selectedColor || null,
          user_avatar_id: selectedUserAvatar ? selectedUserAvatar.id : null,
          duration: isPersistent ? null : duration,
          persist: isPersistent,
        },
      );
      enqueueSnackbar('Message envoyé', { variant: 'success' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Impossible d'envoyer le message", { variant: 'error' });
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (isPersistent) {
      setDuration('');
    } else {
      setDuration(5000);
    }
  }, [isPersistent]);

  useEffect(() => {
    if (isAllUsers) {
      setSelectedUsers([]);
    }
  }, [isAllUsers]);

  return (
    <ThemeProvider>
      <Container maxWidth="xl">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box px={2}>
              <Paper elevation={0}>
                <Box p={2}>
                  <Typography
                    component="div"
                    variant="overline"
                    align="center"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                    }}
                  >
                    <span>Message Administrateur</span>
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <Box mt={2} px={2} pb={2}>
              <BoxLoader loading={formLoading}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper elevation={0}>
                      <Box p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CustomAutocomplete
                              name="users"
                              label="Utilisateurs"
                              value={selectedUsers}
                              endpoint={(input) =>
                                `/users/?search=${
                                  input === 0 || input === undefined
                                    ? ''
                                    : input
                                }&page_size=200&public=True`
                              }
                              getOptionLabel={(o) =>
                                o === '' ? null : o.full_name
                              }
                              renderOption={({ key, ...optionProps }, o) => (
                                <Box component="li" key={key} {...optionProps}>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Avatar
                                        src={`${getApiUrl()}/avatar/${o.id}`}
                                      />
                                    </Grid>
                                    <Grid item>{o.full_name}</Grid>
                                    <Grid item xs />
                                    <Grid item>{o.email}</Grid>
                                  </Grid>
                                </Box>
                              )}
                              onChange={(e, v) => {
                                setSelectedUsers(v);
                              }}
                              size="small"
                              multiple
                              allowNull
                              required
                              disabled={isAllUsers}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAllUsers}
                                  onChange={(e) =>
                                    setIsAllUsers(e.target.checked)
                                  }
                                />
                              }
                              label="Tous les utilisateurs"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        centered
                      >
                        <Tab label="Générique" />
                        <Tab label="Personnel" />
                        <Tab label="URL" />
                      </Tabs>
                    </Box>
                    {(tabValue === 0 || tabValue === 2) && (
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Paper elevation={0}>
                            <Box p={3}>
                              {tabValue === 0 ? (
                                <TextField
                                  select
                                  label="Icône"
                                  fullWidth
                                  id="icon"
                                  value={selectedIcon}
                                  onChange={(e) =>
                                    setSelectedIcon(e.target.value)
                                  }
                                  disabled={formLoading}
                                  size="small"
                                  SelectProps={{
                                    renderValue: (value) => (
                                      <Avatar
                                        sx={{
                                          ...(selectedColor
                                            ? getColor(selectedColor)
                                            : { backgroundColor: 'black' }),
                                          width: 24,
                                          height: 24,
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            webSocketNotificationIcons[value]
                                          }
                                          size="2xs"
                                        />
                                      </Avatar>
                                    ),
                                  }}
                                >
                                  {Object.entries(
                                    webSocketNotificationIcons,
                                  ).map(([key, value]) => (
                                    <MenuItem value={key} key={key}>
                                      <Avatar
                                        sx={
                                          selectedColor
                                            ? getColor(selectedColor)
                                            : { backgroundColor: 'black' }
                                        }
                                      >
                                        <FontAwesomeIcon icon={value} />
                                      </Avatar>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              ) : (
                                <TextField
                                  label="URL"
                                  fullWidth
                                  value={url}
                                  onChange={(e) => setUrl(e.target.value)}
                                  disabled={formLoading}
                                  size="small"
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Paper elevation={0}>
                            <Box p={3}>
                              <TextField
                                select
                                label="Couleur"
                                fullWidth
                                id="color"
                                value={selectedColor}
                                onChange={(e) =>
                                  setSelectedColor(e.target.value)
                                }
                                disabled={formLoading}
                                size="small"
                                SelectProps={{
                                  renderValue: (value) => (
                                    <Avatar
                                      sx={{
                                        ...getColor(value),
                                        width: 24,
                                        height: 24,
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          selectedIcon
                                            ? webSocketNotificationIcons[
                                                selectedIcon
                                              ]
                                            : webSocketNotificationIcons.default
                                        }
                                        size="2xs"
                                      />
                                    </Avatar>
                                  ),
                                }}
                              >
                                {Object.entries(palette)
                                  .filter(
                                    ([key, value]) =>
                                      value.main && value.contrastText,
                                  )
                                  .map(([key, value]) => (
                                    <MenuItem value={key} key={key}>
                                      <Avatar sx={getColor(key)}>
                                        <FontAwesomeIcon
                                          icon={
                                            selectedIcon
                                              ? webSocketNotificationIcons[
                                                  selectedIcon
                                                ]
                                              : webSocketNotificationIcons.default
                                          }
                                        />
                                      </Avatar>
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    )}
                    {tabValue === 1 && (
                      <Paper elevation={0}>
                        <Box p={3}>
                          <CustomAutocomplete
                            name="userAvatar"
                            label="Expéditeur"
                            value={selectedUserAvatar}
                            endpoint={(input) =>
                              `/users/?search=${
                                input === 0 || input === undefined ? '' : input
                              }&page_size=20&public=True`
                            }
                            getOptionLabel={(o) =>
                              o === '' ? null : o.full_name
                            }
                            renderOption={({ key, ...optionProps }, o) => (
                              <Box component="li" key={key} {...optionProps}>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item>
                                    <Avatar
                                      src={`${getApiUrl()}/avatar/${o.id}`}
                                    />
                                  </Grid>
                                  <Grid item>{o.full_name}</Grid>
                                  <Grid item xs />
                                  <Grid item>{o.email}</Grid>
                                </Grid>
                              </Box>
                            )}
                            onChange={(e, v) => {
                              setSelectedUserAvatar(v);
                              setTitle(v.full_name);
                            }}
                            size="small"
                            allowNull
                          />
                        </Box>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <Box p={3}>
                            <TextField
                              label="Titre"
                              fullWidth
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              inputProps={{
                                maxLength: 200,
                              }}
                              disabled={formLoading}
                              size="small"
                            />
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <Box p={3}>
                            <TextField
                              label="Contenu"
                              fullWidth
                              multiline
                              rows={4}
                              value={content}
                              onChange={(e) => setContent(e.target.value)}
                              disabled={formLoading}
                              size="small"
                              required
                            />
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <Box p={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isImportant}
                                  onChange={(e) =>
                                    setIsImportant(e.target.checked)
                                  }
                                />
                              }
                              label="Message important"
                            />
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <Box p={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md="auto">
                                <TextField
                                  label="Durée"
                                  type="number"
                                  fullWidth
                                  value={duration}
                                  onChange={(e) => setDuration(e.target.value)}
                                  disabled={formLoading || isPersistent}
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isPersistent}
                                      onChange={(e) =>
                                        setIsPersistent(e.target.checked)
                                      }
                                    />
                                  }
                                  label="Persistant"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={sendMessage}
                          size="large"
                          disabled={
                            formLoading ||
                            (!isAllUsers &&
                              (!selectedUsers || selectedUsers.length === 0)) ||
                            !content ||
                            content === ''
                          }
                          fullWidth
                        >
                          Valider
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </BoxLoader>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mt: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <Box px={2}>
              <Paper elevation={0}>
                <Box p={2}>
                  <Typography
                    component="div"
                    variant="overline"
                    align="center"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                    }}
                  >
                    Prévisualisation
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <Box mt={3} px={3}>
              <BoxLoader loading={false}>
                <Box px={4} py={12}>
                  <WebSocketNotification
                    id="admin-message-demo"
                    message={content}
                    title={title}
                    customColor={selectedColor}
                    customIcon={tabValue === 2 ? url : selectedIcon}
                    customUserAvatarId={
                      selectedUserAvatar ? selectedUserAvatar.id : null
                    }
                    customIsImportant={isImportant}
                  />
                </Box>
              </BoxLoader>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default AdminMessageManager;
