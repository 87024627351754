/**
 * eslint-disable quote-props
 *
 * @format
 */

import {
  faAt,
  faCircle,
  faCircleQuestion,
  faEnvelope,
  faFileContract,
  faFileExport,
  faGun,
  faHandHoldingDollar,
  faTruckMoving,
  faHexagonPlus,
  faMessageCheck,
  faMessageLines,
  faMessageSms,
  faPeopleArrows,
  faPhone,
  faPhoneSlash,
  faPhoneVolume,
  faQuestion,
  faTriangleExclamation,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  green,
  grey,
  indigo,
  lightGreen,
  lime,
  orange,
  pink,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import moment from 'moment';
import { phoneFormatter } from './numbers';

export const defaultStatusPerColumn = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 11,
};

export const genders = {
  1: 'M.',
  2: 'Mme',
  3: 'M. & Mme',
};

export const panelOrientation = {
  portrait: 'Portrait',
  landscape: 'Paysage',
};

export const getPanelOrientation = (orientation) => {
  if (!orientation) return '???';
  return panelOrientation[orientation];
};

export const getGender = (gender) => {
  if (!gender) return '???';
  return genders[gender];
};

export const installationTypes = {
  1: 'Autoconso surplus',
  2: 'Site isolé',
};

export const yesNoProblem = {
  0: 'OUI',
  1: 'NON',
  2: 'Problème',
};

export const technicalVisitStatus = {
  0: 'A planifier',
  1: 'Planifiée / Pas nécessaire',
};

export const vatRates = {
  0: '0 %',
  5.5: '5,5 %',
  10: '10 %',
  20: '20 %',
};

export const vatConversion = {
  0: 0,
  '10.00': 10,
  '20.00': 20,
  '5.50': 5.5,
};

export const transformVatRate = (vatRate) => vatConversion[vatRate] || 20;

export const bigChangeStatus = {
  '-2': 'Re-planifié',
  1: 'Nouveau',
  2: 'Planifié',
  3: 'Dé-planifié',
  4: 'Envoyé',
  5: 'Lu',
  6: 'Accepté',
  7: 'Refusé',
  8: 'En route',
  10: 'Démarré',
  11: 'Suspendu',
  12: 'Terminé',
  13: 'Terminé avec problèmes',
  14: 'Annulé',
  20: 'Démarré en retard',
  21: 'Terminé en retard',
};

export const bigChangeStatusColor = {
  '-2': {
    bgcolor: pink[500],
    color: 'white',
  },
  1: {
    bgcolor: cyan[100],
    color: 'text.primary',
  },
  2: {
    bgcolor: teal[500],
    color: 'white',
  },
  3: {
    bgcolor: brown[300],
    color: 'white',
  },
  4: {
    bgcolor: lime[200],
    color: 'text.primary',
  },
  5: {
    bgcolor: lime[500],
    color: 'text.primary',
  },
  6: {
    bgcolor: lightGreen[400],
    color: 'text.primary',
  },
  7: {
    bgcolor: deepOrange[400],
    color: 'white',
  },
  8: {
    bgcolor: indigo[500],
    color: 'white',
  },
  10: {
    bgcolor: blue[500],
    color: 'white',
  },
  11: {
    bgcolor: amber[500],
    color: 'text.primary',
  },
  12: {
    bgcolor: green[500],
    color: 'white',
  },
  13: {
    bgcolor: yellow[500],
    color: 'text.primary',
  },
  14: {
    bgcolor: grey[400],
    color: 'text.primary',
  },
  20: {
    bgcolor: blue[500],
    color: 'white',
  },
  21: {
    bgcolor: green[500],
    color: 'white',
  },
};

export const getBigChangeStatusColor = (index) =>
  bigChangeStatusColor[index] || {
    bgcolor: '#ffffff',
    color: '#000000',
  };

// noinspection JSNonASCIINames
export const bigChangeFlagColor = {
  'À étudier': {
    bgcolor: red[500],
    color: 'white',
  },
  'Contacter client': {
    bgcolor: red[700],
    color: 'white',
  },
  'En attente pièce': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'En attente retour client': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'En attente retour interne': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'En attente retour support': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'Intervention demandée': {
    bgcolor: orange[800],
    color: 'white',
  },
  'Intervention planifiée': {
    bgcolor: amber[500],
    color: 'text.primary',
  },
  'Intervention terminée': {
    bgcolor: green[500],
    color: 'white',
  },
  'Recherches en cours': {
    bgcolor: amber[500],
    color: 'text.primary',
  },
  Résolu: {
    bgcolor: green[500],
    color: 'white',
  },
  'RMA - Demande effectuée': {
    bgcolor: null,
    color: 'text.primary',
  },
  'RMA - En attente pièce': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'RMA - Faire une demande': {
    bgcolor: red[500],
    color: 'white',
  },
  'RMA - Pièce renvoyée (checker remboursement)': {
    bgcolor: indigo[500],
    color: 'white',
  },
  'RMA - Renvoyer pièce': {
    bgcolor: red[500],
    color: 'white',
  },
};

export const getBigChangeFlagColor = (name) =>
  bigChangeFlagColor[name] || {
    bgcolor: '#ffffff',
    color: '#000000',
  };

export const products = {
  solar_panels: 'panneaux solaires',
  inverters: 'onduleurs',
  optimisers: 'optimiseurs',
  monitorings: 'monitorings',
  electrical_protections: 'protections électriques',
  cables: 'câbles',
  structures: 'kits de structure',
  workforces: "main d'œuvre",
  administrative_procedures: 'démarches administratives',
};

export const productsV2 = {
  solar_panel: 'panneaux solaires',
  inverter: 'onduleur',
  optimiser: 'optimiseur',
  monitoring: 'monitoring',
  electrical_protection: 'protection électrique',
  cable: 'câble',
  structure: 'kit de structure',
  workforce: "main d'œuvre",
  administrative_procedure: 'démarche administrative',
};

export const mailDefaults = {
  subject: 'Solaire - Libow',
  body: (
    gender,
    lastName,
    managerName,
    managerNumber,
    managerMobileNumber,
    dataSheets = null,
    dataSheetsAsAttachment = false,
    multi = false,
  ) => `<p>Bonjour ${gender} ${lastName},<br> 
Vous trouverez ci-joint ${
    multi ? 'nos propositions' : 'notre proposition'
  } pour votre projet.</p>
${
  dataSheets &&
  dataSheets.filter((dataSheet) => dataSheet !== null && dataSheet !== '')
    .length > 0
    ? `<p>Voici les fiches techniques des produits proposés : <ul>${dataSheets
        .filter((dataSheet) => dataSheet !== null && dataSheet !== '')
        .map(
          (dataSheet) =>
            `<li><a href="${dataSheet}" target="_blank">${dataSheet}</a></li>`,
        )}</ul></p>`
    : ''
}
${
  dataSheetsAsAttachment
    ? '<p>Les fiches techniques des produits proposés vous seront envoyées dans un prochain e-mail.</p>'
    : ''
}
<p>Restant à votre disposition et bien cordialement,</p>
<p><b>${managerName}</b></p>
${
  managerNumber && managerMobileNumber
    ? `<p>${managerNumber ? phoneFormatter(managerNumber) : ''}<br>${
        managerMobileNumber ? phoneFormatter(managerMobileNumber) : ''
      }</p>`
    : ''
}
<p></p>
<p>
<a href="www.libow.fr" target="_blank">www.libow.fr</a>
<br> 
Made in Occitanie !
</p>
<p></p> 
<p>
Votre Spécialiste en Autonomie Energétique
<br>
Devis — Pose — Maintenance — Accompagnement
</p> 
<p></p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">
<p></p>`,
};

export const mailDefaultDP = {
  subject: (id) =>
    `Libow Solaire - Déclaration préalable envoyée (Réf. #${id})`,
  body: (
    gender,
    lastName,
    managerName,
    managerFirstName,
  ) => `<p>Bonjour ${gender} ${lastName},</p>
<p>Bienvenue chez Libow ! Je suis ${managerFirstName} et serai chargée de la réalisation des démarches administratives pour votre projet.</p>
<p>Je vous informe que l'envoi de votre déclaration préalable a bien été effectué.</p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br><br>
${managerName}, votre chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
<p></p>
<p><a href="www.libow.fr" target="_blank">www.libow.fr</a><br>
Made in Occitanie !</p>
<p></p> 
<p>Votre Spécialiste en Autonomie Energétique<br>
Devis — Pose — Maintenance — Accompagnement</p> 
<p></p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">
<p></p>`,
};

export const mailDefaultPlanningClient = {
  subject: (id) => `Planification de votre chantier (Réf. #${id})`,
  body: (gender, lastName, dates, advance, advanceRate, withAdvance, user) => {
    let stringDates = '';
    if (dates.length === 1) {
      stringDates += `le ${moment(dates[0].date).format('LL')} à ${moment(
        dates[0].date,
      ).format('HH[h]mm')}`;
    } else {
      dates.sort((d1, d2) => (d1.date > d2.date ? 1 : -1));
      const hours = dates.map((d) => moment(d.date).format('HH[h]mm'));
      let sameHours = true;
      hours.forEach((h, i) => {
        if (i < hours.length - 1) {
          if (h !== hours[i + 1]) {
            sameHours = false;
          }
        }
      });
      dates.forEach((d, i) => {
        if (sameHours) {
          if (i === 0) {
            stringDates += `le ${moment(d.date).format('Do MMMM')}`;
          } else if (i + 1 < dates.length) {
            stringDates += `, ${moment(d.date).format('Do MMMM')}`;
          } else if (i + 1 === dates.length) {
            stringDates += ` et ${moment(d.date).format('LL')} à ${moment(
              d.date,
            ).format('HH[h]mm')}`;
          }
        } else if (i === 0) {
          stringDates += `le ${moment(d.date).format('Do MMMM')} à ${moment(
            d.date,
          ).format('HH[h]mm')}`;
        } else if (i + 1 < dates.length) {
          stringDates += `, ${moment(d.date).format('Do MMMM')} à ${moment(
            d.date,
          ).format('HH[h]mm')}`;
        } else if (i + 1 === dates.length) {
          stringDates += ` et le ${moment(d.date).format('Do MMMM')} à ${moment(
            d.date,
          ).format('HH[h]mm')}`;
        }
      });
    }
    return `<p>Bonjour ${gender} ${lastName},</p>
<p>Je vous confirme que notre équipe pourra intervenir <b>${stringDates}.</b></p>
${
  withAdvance
    ? '<p>Un second mail vient de vous être envoyé avec notre RIB pour le paiement de votre acompte.</p>'
    : ''
}
<p></p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br>
${user.first_name} ${user.last_name}, ${
      user.is_planning_leader
        ? 'votre responsable des opérations.'
        : `votre assistant${
            user.last_name === 'DA SILVA' ? 'e' : ''
          } planification.`
    }<br/>
${user.email}<br/>
${phoneFormatter(user.mobile_phone)}</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
<p></p>
<p><a href="www.libow.fr" target="_blank">www.libow.fr</a><br> 
Made in Occitanie !</p>
<p></p> 
<p>Votre Spécialiste en Autonomie Energétique<br>
Devis — Pose — Maintenance — Accompagnement</p> 
<p></p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">
<p></p>`;
  },
};

export const mailDefaultPlanning = {
  subject: (power, gender, firstName, lastName, id) =>
    `Installation ${power
      .toString()
      .replace(
        '.',
        ',',
      )} kWc - ${gender} ${firstName} ${lastName} (Réf. #${id})`,
  body: (pv, listProducts, comment, address, mail, phone) => {
    let body = '';
    if (pv !== '') {
      body += `<p>${pv}</p>`;
    }
    if (listProducts !== null) {
      body += '<p>';
      listProducts.forEach((p, index) => {
        if (index === listProducts.length - 1) {
          body += `${p}</p>`;
        } else {
          body += `${p}<br>`;
        }
      });
    }
    body += `<p>Message : ${comment.replaceAll('\n', '<br>')}</p>
<p><b>Adresse : </b>${address}<br>
<b>E-mail : </b>${mail}<br>
<b>Téléphone : </b>${phoneFormatter(phone)}</p>`;

    return body;
  },
};

export const clientTab = {
  general: 1,
  town_hall: 2,
  connection: 3,
  planning: 4,
  consuel: 5,
  edf_oa: 6,
  bill: 8,
};

export const clientStatusModel = {
  2: '/clients-prior-request-status/',
  3: '/clients-enedis-status/',
  4: '/clients-planning-status/',
  5: '/clients-consuel-status/',
  6: '/clients-edf-oa-contract-action-status/',
};

export const productCategoriesById = {
  1: 'products-solar-panels/',
  2: 'products-batteries/',
  3: 'products-monitorings/',
  4: 'products-inverters/',
  5: 'products-cables/',
  6: 'products-electrical-protections/?category__id=6',
  7: 'products-structures/',
  8: 'products-monitorings/?category__id=8',
  9: 'products-administrative-procedures/?category__id=9',
  10: 'products-workforces/',
  11: 'products-optimisers/',
  13: 'products-electrical-protections/?category__id=13',
  14: 'products-electrical-protections/?category__id=14',
  17: 'products-thermo-balloon/',
  18: 'products-workforces-no-pv/?category__id=18',
  19: 'products-regulators/',
  20: 'products-materials/?category__id=20',
  21: 'products-air-conditioning',
  22: 'products-workforces-no-pv/?category__id=22',
  23: 'products-heat-pump/',
  24: 'products-heat-pump-supply/',
  25: 'products-workforces-no-pv/?category__id=25',
  26: 'products-change-inverter/',
  27: 'products-workforces-no-pv/?category__id=27',
  28: 'products-other-material/',
  29: 'products-other-service/',
  30: 'products-air-conditioning_supply/',
  31: 'products-battery-hubs/',
  32: 'products-inverter-accessories/',
  33: 'products-workforce-supply-batteries/',
};

export const mailDefaultFirstBill = {
  subject: (id) => `Facturation EDFOA (Réf. ${id})`,
  body: (
    gender,
    lastName,
    serviceStartDate,
    billingDate,
    managerName,
  ) => `<p>Bonjour ${gender} ${lastName}</p>
<p>Votre raccordement avec Enedis ayant été finalisé le <b>${serviceStartDate}</b>, 
nous allons pouvoir très prochainement établir votre première facture avec EDFOA pour le rachat de votre surplus solaire.</p>
<p>Pour cela, je me permets de venir vers vous afin de vous demander s’il était possible de faire <b>parvenir à la date du ${billingDate}</b> 
une photo de votre index injection (indiquée sur votre compteur Linky) ainsi que votre RIB à l’adresse admin@libow.fr ?</p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br>
${managerName}, votre chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
<p>www.libow.fr</br>
Made in Occitanie !</p>
<p>Votre Spécialiste en Autonomie Energétique</br>
Devis – Pose – Maintenance – Accompagnement</p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">`,
};

export const mailDefaultNextBill = {
  subject: (id) => `Facturation EDFOA (Réf. ${id})`,
  body: (
    gender,
    lastName,
    serviceStartDate,
    billingDate,
    managerName,
  ) => `<p>Bonjour ${gender} ${lastName}</p>
<p>Votre raccordement avec Enedis ayant été finalisé le <b>${serviceStartDate}</b>, 
nous allons pouvoir très prochainement établir votre prochaine facture avec EDFOA pour le rachat de votre surplus solaire.</p>
<p>Pour cela, je me permets de venir vers vous afin de vous demander s’il était possible de faire <b>parvenir à la date du ${billingDate}</b> 
une photo de votre index injection (indiquée sur votre compteur Linky) ainsi que votre RIB, si ce dernier a changé, à l’adresse admin@libow.fr ?</p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br>
${managerName}, votre chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
<p>www.libow.fr</br>
Made in Occitanie !</p>
<p>Votre Spécialiste en Autonomie Energétique</br>
Devis – Pose – Maintenance – Accompagnement</p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">`,
};

export const mailDefaultBillingDone = {
  subject: (id) => `Facturation EDFOA (Réf. #${id})`,
  body: (gender, lastName, managerName) => `<p>Bonjour ${gender} ${lastName}</p>
<p>Je viens de faire votre facture. Elle est actuellement en cours d'analyse par les services EDF OA.</p>
<p>Une fois validée, le règlement devrait intervenir d'ici un mois.</p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br>
${managerName}, votre chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
04 99 63 51 70</p>
<p>www.libow.fr</br>
Made in Occitanie !</p>
<p>Votre Spécialiste en Autonomie Energétique</br>
Devis – Pose – Maintenance – Accompagnement</p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">`,
};

export const mailDefaultServiceStart = {
  subject: (id) =>
    `Mise en service effective et suite des démarches (Réf. #${id})`,
  body: (
    gender,
    lastName,
    serviceStartDate,
    managerName,
  ) => `<p>Bonjour ${gender} ${lastName},</p>
<p>J'ai le plaisir de vous transmettre votre attestation Consuel et de vous informer que votre mise en service est effective depuis le ${serviceStartDate}.
Cela signifie que vous revendez votre surplus depuis cette date.</p>
<p>Si vous avez choisi d'être mandaté(e) par Libow pour vos démarches de rachat d'électricité, voici 
la prochaine étape : vous devriez recevoir un courrier d'EDF avec votre numéro BTA. Il est important 
que vous me le transmettiez aussitôt pour que je puisse effectuer vos démarches auprès d'EDF OA.</br>
Si vous ne recevez pas votre numéro BTA dans les trois mois précédant la date d'anniversaire de votre mise en service, merci de nous en tenir informé afin que nous puissions effectuer les démarches pour le récupérer.
En vous remerciant par avance,</p>
<p></p>
<p>Je reste disponible pour tout complément d'information et vous souhaite une bonne journée.</p>
<p>Bien à vous,<br>
${managerName}, votre chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70<br>
Assistance administrative : admin@libow.fr<br>
Assistance technique : sav@libow.fr<br>
Facturation / Comptabilité : compta@libow.fr</p>
<p>www.libow.fr</br>
Made in Occitanie !</p>
<p>Votre Spécialiste en Autonomie Energétique</br>
Devis – Pose – Maintenance – Accompagnement</p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">`,
};

export const mailDefaultPriorRequest = {
  subject: (gender, lastName, id) =>
    `Dépôt de déclaration préalable de travaux - ${gender} ${lastName} (Réf. #${id})`,
  body: (gender, lastName, address, managerName) => `<p>Bonjour,</p>
<p>Vous trouverez ci-joint le dossier de déclaration préalable de travaux de ${gender} ${lastName} pour l'adresse ${address}.</p>
<p>Vous serait-il possible s'il vous plait de nous faire parvenir le récépissé de dépôt de la demande par e-mail ?</p>
<p></p>
<p>Je reste à votre disposition pour tout complément.</p>
<p>Vous remerciant et bien cordialement,<br>
${managerName}, chargée d'administration.</p>
<p>Accueil téléphonique : 04 99 63 51 70</p>
<p>www.libow.fr</br>
Made in Occitanie !</p>
<p>Votre Spécialiste en Autonomie Energétique</br>
Devis – Pose – Maintenance – Accompagnement</p>
<img src="https://www.libow.fr/wp-content/uploads/2022/03/image.png" alt="Logo de Libow" width="300" height="90">`,
};

export const clientInfo = (lead, site, provenance, tab) => {
  if (tab === 8) {
    return `${lead.address_1} ${site.zip_code} ${site.city}`;
  }
  return `${genders[lead.gender]} ${lead.first_name} ${lead.last_name}
Adresse : ${lead.address_1} ${site.zip_code} ${site.city}
E-mail : ${lead.email}
Téléphone : ${lead.phone_1}
Provenance : ${provenance.title}`;
};

export const leadInfo = (lead) =>
  `${lead.address_1} ${lead.zip_code} ${lead.city}`;

export const addressNameFormat = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  postal_code: 'short_name',
};

export const getAddressComp = (addressComponents, type) => {
  const component = addressComponents.find((comp) => comp.types[0] === type);
  if (component) {
    return component[addressNameFormat[type]];
  }
  return '';
};

export const billingConditionsText = (
  hasBattery,
  withTownHall,
  hasSB,
  account,
  withConsuel,
) => {
  let conditions =
    "Vous bénéficiez d'un droit de délai de rétractation de 15 jours.";

  if (withTownHall) {
    conditions +=
      ' En cas de refus du projet par la mairie, le devis sera réputé nul et non avenu.';
  }

  conditions += '\nNos conditions de paiements sont les suivantes :';

  conditions += `\n - Versement d'un acompte de ${account}%${
    withTownHall ? " à l'acceptation du projet par la mairie" : ''
  }`;
  if (hasBattery || hasSB) {
    conditions += ` (un acompte de ${account}% à la place des 30% habituellement pratiqués est demandé pour toute commande comportant du Sun Ballast ou des batteries)`;
  }
  conditions +=
    ". C'est le versement de cet acompte sur notre compte qui conditionnera la mise en planification de votre chantier par notre équipe de planification " +
    "(merci de bien vouloir mettre votre nom ou la référence de la facture sur votre virement afin d'en faciliter l'identification par notre service de compatibilité).";

  conditions += `\n - Le paiement du solde complet au jour de la fin de chantier, c'est-à-dire dès lors que l'installation est en production${
    withConsuel
      ? " (l'attestation Consuel ne sera délivrée qu'à paiement complet du solde)."
      : '.'
  }`;

  return conditions;
};

export const asanaColorMapping = {
  red: {
    backgroundColor: '#e9445a',
    color: 'white',
  },
  orange: {
    backgroundColor: '#fd612c',
    color: 'black',
  },
  'yellow-orange': {
    backgroundColor: '#fd9a00',
    color: 'black',
  },
  yellow: {
    backgroundColor: '#eec300',
    color: 'black',
  },
  'yellow-green': {
    backgroundColor: '#a4cf30',
    color: 'black',
  },
  green: {
    backgroundColor: '#62d26f',
    color: 'black',
  },
  'blue-green': {
    backgroundColor: '#37c5ab',
    color: 'black',
  },
  aqua: {
    backgroundColor: '#20aaea',
    color: 'black',
  },
  blue: {
    backgroundColor: '#4186e0',
    color: 'white',
  },
  indigo: {
    backgroundColor: '#7a6ff0',
    color: 'black',
  },
  purple: {
    backgroundColor: '#aa62e3',
    color: 'black',
  },
  magenta: {
    backgroundColor: '#e56ce5',
    color: 'black',
  },
  'hot-pink': {
    backgroundColor: '#eb59a3',
    color: 'black',
  },
  pink: {
    backgroundColor: '#fc91ad',
    color: 'black',
  },
  'cool-gray': {
    backgroundColor: '#8da3a6',
    color: 'black',
  },
  none: {
    backgroundColor: '#ffffff',
    color: 'black',
  },
};

export const FILES_NAME = {
  additional_documents: 'Docs complémentaires',
  additional_documents_request: 'Demande docs complémentaires',
  addressing_certificate: "Certificat d'adressage",
  box_photo: 'Coffret électrique',
  breaker_photo: 'Disjoncteur général',
  bta_number_letter: 'Courrier N° BTA',
  cae: "CAE — Contrat d'Accès et d'Exploitation",
  client_discharge: 'Décharge client urbanisme',
  collect_file: 'Fiche collecte',
  complete_prior_request_file: 'Dossier DP complet',
  connection_mandate: 'Mandat raccordement',
  connection_request_postal_acknowledgement: 'A/R Demande de racco',
  construction_vp: 'PV de fin de chantier signé',
  consuel: 'Attestation Consuel',
  consuel_non_conformity_letter: 'Courrier non conformité Consuel',
  consuel_visit_date_letter: 'Courrier date visite',
  consuel_visit_week_letter: 'Courrier semaine visite',
  daact: 'DAACT',
  decree: 'Arrêté mairie',
  dp2_local: 'DP2 local',
  edf_oa_mandate: 'Mandat EDF OA signé',
  edf_oa_signed_contract: 'Contrat OA signé',
  electricity_bill: "Facture d'électricité",
  extension: 'Prolongation instruction',
  iban: 'RIB',
  identity_document: "Document d'identité",
  kbis: 'KBIS',
  last_available_invoice: 'Dernière facture disponible',
  layout_photo: 'Plan de calepinage',
  meter_photo: 'Compteur électrique',
  one_line_diagram: 'Schéma unifilaire',
  pdr: 'PDR — Proposition De Raccordement',
  prefilled_construction_end_report: 'PV de fin de chantier pré-rempli',
  prefilled_edf_oa_mandate: 'Mandat EDF OA pré-rempli',
  prior_request: 'Déclaration préalable',
  prior_request_postal_acknowledgement: 'A/R DP',
  producer_certificate: 'Attestation producteur',
  installer_certificate: 'Attestation installateur',
  property_tax: 'Taxe foncière',
  receipt: 'Récepissé mairie',
  refusal: 'Refus mairie',
  signed_quote: 'Devis signé',
  technical_file: 'Dossier technique',
  tgbt_photo: 'TGBT — Tableau Général Basse Tension',
  town_hall_agreement: 'Accord mairie',
  town_hall_agreement_under_conditions: 'Accord mairie sous conditions',
  town_hall_appeal: 'Recours mairie',
  town_hall_mandate: 'Mandat mairie',
  town_hall_refusal: 'Refus mairie',
  town_hall_time_extension: 'Prolongation instruction',
  various: 'Divers',
  solar_panel_warranty: 'Garantie panneaux solaires',
  inverter_warranty: 'Garantie onduleur(s)',
};

export const fileTypeToFieldMapping = {
  prior_request_postal_acknowledgement: [
    'town_hall.prior_request_postal_acknowledgement_date',
  ],
  connection_request_postal_acknowledgement: [
    'connection.connection_request_date',
  ],
  town_hall_agreement: ['town_hall.acceptation_date'],
  town_hall_agreement_under_conditions: ['town_hall.acceptation_date'],
  consuel: ['consuel.validation_date'],
  bta_number_letter: ['bta_number'],
  consuel_visit_date_letter: ['consuel.visit_date'],
  town_hall_time_extension: [
    'town_hall.abf_status',
    'town_hall.processing_time',
  ],
  town_hall_refusal: ['town_hall.refusal_date'],
  receipt: ['town_hall.receipt_date'],
  town_hall_mandate: ['town_hall.is_connection_mandate'],
  edf_oa_signed_contract: ['edf_oa.sign_date'],
  // property_tax: ['text.property_tax'],
};

const eventActionIcons = {
  lead_status_change: faCircle,
  quote_created: faHexagonPlus,
  lead_created: faHexagonPlus,
  site_created: faHexagonPlus,
  deal_created: faHexagonPlus,
  offer_sent: faFileExport,
  missed_call: faPhoneSlash,
  communication: faMessageCheck,
  voicemail: faPhoneVolume,
  comment: faMessageLines,
  mail_sent: faEnvelope,
  text_message_sent: faMessageSms,
  mail_tried: faTriangleExclamation,
  text_message_tried: faTriangleExclamation,
  planning_status_change: null,
  younited_initialized: faFileContract,
  younited_granted: faFileContract,
  younited_confirmed: faFileContract,
  younited_financed: faFileContract,
  younited_withdrawn: faFileContract,
  younited_canceled: faFileContract,
  younited_rejected: faFileContract,
};

export const getEventActionIcon = (action) => {
  if (!action) return faQuestion;
  const actionType = Object.entries(action)
    .find(([key, value]) => key.startsWith('is_') && value === true)[0]
    .replace('is_', '');
  return eventActionIcons[actionType] || faQuestion;
};

const provenanceIcons = {
  phone: {
    icon: faPhone,
    color: 'green',
  },
  internet: {
    icon: faAt,
    color: 'teal',
  },
  referrer: {
    icon: faPeopleArrows,
    color: 'red',
  },
  business_introducer: {
    icon: faHandHoldingDollar,
    color: 'orange',
  },
  relocation: {
    icon: faTruckMoving,
    color: 'lightBlue',
  },
  other: {
    icon: faCircleQuestion,
    color: 'gray',
  },
};

export const getProvenanceIcon = (provenance) => {
  if (!provenance) return faQuestion;
  return provenanceIcons[provenance.type] || faCircleQuestion;
};

/**
 * Vérifie si un numéro de téléphone est un portable.
 * @param {string} phoneNumber - Le numéro de téléphone à vérifier.
 * @returns {boolean} - Retourne `true` si le numéro est un portable, sinon `false`.
 */
export const isMobilePhoneNumber = (phoneNumber) => {
  // Expression régulière pour les numéros de téléphone portable
  const mobilePhonePattern = /^(06|07)\d{8}$/;

  // Vérifie si le numéro correspond au modèle de téléphone portable
  return mobilePhonePattern.test(phoneNumber);
};

export default null;

export const MEETING_TYPE_CHOICES = {
  r1: 'R1',
  r2: 'R2',
  technical_visit: 'VT',
  r3: 'R3',
};
