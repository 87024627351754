/** @format */

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import {
  BasicFuncs,
  Builder,
  MuiConfig,
  Query,
  Utils as QbUtils,
} from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';
import { clone } from 'lodash';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { api } from '../../../api.ts';
import { ThemeProvider } from '../../../theme';
import BoxLoader from '../../misc/BoxLoader';
import CustomAutocomplete from '../../misc/CustomAutocomplete';

const MATHS = {
  label: 'Opération',
  returnType: 'number',
  renderBrackets: ['(', ')'],
  renderSeps: ['', '', ''],
  allowSelfNesting: true,
  jsonLogic: ({ x, op, y }) => ({
    maths: [x, op, y],
  }),
  jsonLogicImport: (v) => {
    const x = v.maths[0];
    const op = v.maths[1];
    const y = v.maths[2];
    return [x, op, y];
  },
  jsonLogicCustomOps: {
    maths: {},
  },
  args: {
    x: {
      type: 'number',
      valueSources: ['field', 'value', 'func'],
    },
    op: {
      label: 'Op',
      type: 'select',
      defaultValue: 'add',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false,
        },
      },
      fieldSettings: {
        listValues: {
          add: '+',
          substract: '-',
          multiply: '*',
          divide: '/',
        },
      },
    },
    y: {
      type: 'number',
      valueSources: ['field', 'value', 'func'],
    },
  },
};

const baseSortConfig = {
  ...MuiConfig,
  conjunctions: {
    AND: MuiConfig.conjunctions.AND,
  },
  fields: {},
  funcs: {},
  operators: {
    equal: MuiConfig.operators.equal,
  },
  types: {
    ...MuiConfig.types,
    boolean: {
      ...MuiConfig.types.boolean,
      mainWidgetProps: {
        labelYes: 'Croissant',
        labelNo: 'Décroissant',
      },
      valueSources: ['value'],
      defaultValue: true,
    },
  },
  settings: {
    ...MuiConfig.settings,
    showNot: false,
    maxNesting: 1,
  },
};

const baseConfig = {
  ...MuiConfig,
  fields: {},
  funcs: {
    LOWER: BasicFuncs.LOWER,
    UPPER: BasicFuncs.UPPER,
    NOW: BasicFuncs.NOW,
    RELATIVE_DATETIME: BasicFuncs.RELATIVE_DATETIME,
    MATHS,
  },
  operators: {
    ...Object.entries(MuiConfig.operators)
      .filter(([key]) => key !== 'proximity')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {}),
  },
  settings: {
    ...MuiConfig.settings,
  },
};

const defaultQueryValue = QbUtils.loadTree({
  id: QbUtils.uuid(),
  type: 'group',
});

function AdminOptionCompatibilityManager() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [optionGroup, setOptionGroup] = useState('');
  const [config, setConfig] = useState(null);

  const [compatibilityTree, setCompatibilityTree] = useState(defaultQueryValue);

  const onCompatibilityChange = useCallback((immutableTree, cnf) => {
    setCompatibilityTree(immutableTree);
  }, []);

  const onOptionGroupChange = useCallback(async (event, value) => {
    setOptionGroup(value);
    if (value) {
      setLoading(true);
      try {
        const localOptionGroup = (
          await api.get(`/products/v2/option-groups/${value.id}/`)
        )?.data;
        const localConfig = clone(baseConfig);
        const logicConfig = (
          await api.get(`/products/v2/logic-configuration/?use_quote=True`)
        )?.data;
        localConfig.fields = logicConfig.fields;
        setConfig(localConfig);
        if (
          localOptionGroup.compatibility_logic &&
          Object.keys(localOptionGroup.compatibility_logic).length > 0
        ) {
          setCompatibilityTree(
            QbUtils.loadFromJsonLogic(
              localOptionGroup.compatibility_logic,
              localConfig,
            ),
          );
        } else {
          setCompatibilityTree(defaultQueryValue);
        }
      } catch (e) {
        enqueueSnackbar('Erreur lors du chargement des champs', {
          variant: 'error',
        });
      }
      setLoading(false);
    }
  }, []);

  const saveConfig = useCallback(async () => {
    setLoading(true);
    const compatibilityLogic = QbUtils.jsonLogicFormat(
      compatibilityTree,
      config,
    );
    const data = {
      compatibility_logic: compatibilityLogic.logic || {},
    };
    try {
      const response = await api.patch(
        `/products/v2/option-groups/${optionGroup.id}/`,
        {},
        data,
      );
      enqueueSnackbar('Configuration sauvegardée !', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar("Erreur lors de l'enregistrement", {
        variant: 'error',
      });
    }
    setLoading(false);
  }, [compatibilityTree, config, optionGroup?.id]);

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container">
        <div
          className="query-builder qb-lite"
          style={{
            margin: 0,
            marginRight: '-10px',
          }}
        >
          <Builder {...props} />
        </div>
      </div>
    ),
    [],
  );

  return (
    <ThemeProvider>
      <Container maxWidth="xl">
        <Typography variant="h3" gutterBottom>
          Configurateur de logique option
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={2}>
            <CustomAutocomplete
              name="optionGroup"
              label="Groupe d'options"
              value={optionGroup}
              endpoint={(input) =>
                `/products/v2/option-groups/?search=${
                  input === 0 || input === undefined ? '' : input
                }`
              }
              getOptionLabel={(o) => o.name}
              onChange={onOptionGroupChange}
              size="small"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !optionGroup}
              onClick={saveConfig}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
        <BoxLoader loading={loading} />
        {optionGroup && (
          <>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h4">Compatibilité</Typography>
              <Typography variant="subtitle2" color="gray" gutterBottom>
                Ce groupe d&apos;options sera proposé dans les offres
                correspondant aux conditions ci-dessous
              </Typography>
              {config && compatibilityTree && !loading && (
                <>
                  <Query
                    {...config}
                    value={compatibilityTree}
                    onChange={onCompatibilityChange}
                    renderBuilder={renderBuilder}
                    key={`${optionGroup.id}-compatibility`}
                  />
                  {/* <div className="query-builder-result">
                    <div>
                      <pre style={{ whiteSpace: 'pre-wrap' }}>
                        {JSON.stringify(
                          QbUtils.jsonLogicFormat(compatibilityTree, config),
                        )}
                      </pre>
                    </div>
                  </div> */}
                </>
              )}
            </Box>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default AdminOptionCompatibilityManager;
