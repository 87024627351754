/** @format */

// ----------------------------------------------------------------------

export default function Icon(theme) {
  return {
    MuiIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto',
          fontSize: '1.25rem',
          padding: '.25rem',
        },
        fontSizeSmall: {
          fontSize: '1rem',
        },
        fontSizeLarge: {
          fontSize: '1.75rem',
        },
      },
    },
  };
}
