/** @format */

import { createContext, useContext } from 'react';

const ManywattContext = createContext(null);

export const useManywatt = () => useContext(ManywattContext);

export default ManywattContext;

export const QuoteDialogContext = createContext(null);

export const useQuoteDialogContext = () => useContext(QuoteDialogContext);
