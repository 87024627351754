/** @format */

import moment from 'moment';

/**
 * Compares elements of a boolean list
 * @param a
 * @param b
 * @param desc
 * @returns {number}
 */
export const compareBools = (a, b, desc = false) => {
  if (desc) {
    if (a && !b) return 1;
    if (!a && b) return -1;
  }
  if (a && !b) return -1;
  if (!a && b) return 1;
  return 0;
};

/**
 * Compares elements of a number list
 * @param a
 * @param b
 * @param desc
 * @returns {number}
 */
export const compareNumbers = (a, b, desc = false) => {
  if (desc) {
    if (a > b) return -1;
    if (a < b) return 1;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/**
 * Sorts a list of string by a given key
 * @param criteria
 */
export const sortByString = (criteria) => (a, b) => {
  if (a[criteria] > b[criteria]) return 1;
  if (a[criteria] < b[criteria]) return -1;
  return 0;
};

/**
 * Sorts a list of numbers by a given key
 * @param criteria
 */
export const sortBy = (criteria) => (a, b) => {
  if (parseFloat(a[criteria]) > parseFloat(b[criteria])) return 1;
  if (parseFloat(a[criteria]) < parseFloat(b[criteria])) return -1;
  return 0;
};

/**
 * Sorts a list of numbers by a given key in desc order
 * @param criteria
 */
export const sortByDesc = (criteria) => (a, b) => {
  if (parseFloat(a[criteria]) > parseFloat(b[criteria])) return -1;
  if (parseFloat(a[criteria]) < parseFloat(b[criteria])) return 1;
  return 0;
};

/**
 * Sorts a list of dates by a given key
 * @param criteria
 */
export const sortByDate = (criteria) => (a, b) => {
  if (moment(a[criteria]) > moment(b[criteria])) return 1;
  if (moment(a[criteria]) < moment(b[criteria])) return -1;
  return 0;
};

/**
 * Sorts a list of objects by a given attribute key
 * @param criteria
 * @param object
 */
export const customSortByString = (criteria, object) => (a, b) => {
  if (object[a[criteria]] > object[b[criteria]]) return 1;
  if (object[a[criteria]] < object[b[criteria]]) return -1;
  return 0;
};

export default null;

const vat = {
  a: '10',
  b: '20',
  c: '30',
};

const vatTab = [
  ['a', '10'],
  ['b', '20'],
  ['c', '30'],
];
